import {
  SET_CATEGORIES,
  SET_CATEGORIES_LOADING,
  SET_NEWS,
  SET_NEWS_LOADING,
  START_FETCH_CATEGORIES,
  START_FETCH_NEWS,
} from '../const/news.const';

export const startFetchNews = (page = 0) => ({
  type: START_FETCH_NEWS,
  payload: page,
});

export const setNewsLoading = (payload) => ({
  type: SET_NEWS_LOADING,
  payload,
});

export const setNews = (payload) => ({
  type: SET_NEWS,
  payload,
});

export const startFetchCategories = () => ({
  type: START_FETCH_CATEGORIES,
});

export const setCategoriesLoading = (payload) => ({
  type: SET_CATEGORIES_LOADING,
  payload,
});

export const setCategories = (payload) => ({
  type: SET_CATEGORIES,
  payload,
});
