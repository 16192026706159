import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PrivateRoute } from './components/common/PrivateRoute';
import { ScrollToTop } from './components/common/ScrollTop';
import { ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

const Layout = loadable(() => import('./Layout/Layout'), {
  fallback: <div>Loading...</div>,
});

const LoginContainer = loadable(() => import('./Modules/Auth/Login'), {
  fallback: <div>Loading...</div>,
});

const NewsContainer = loadable(() => import('./Modules/News/NewsContainer'), {
  fallback: <div>Loading...</div>,
});

const NewModelsSliderContainer = loadable(() => import('./Modules/Home/slider/NewModelsSlider'), {
  fallback: <div>Loading...</div>,
});
const MainSliderContainer = loadable(() => import('./Modules/Home/slider/MainSlider'), {
  fallback: <div>Loading...</div>,
});

const CarsContainer = loadable(() => import('./Modules/car/CarsContainer'), {
  fallback: <div>Loading...</div>,
});

const AboutContainer = loadable(() => import('./Modules/About/AboutContainer'), {
  fallback: <div>Loading...</div>,
});
const ModelsContainer = loadable(() => import('./Modules/Models/ModelContainer'), {
  fallback: <div>Loading...</div>,
});

const ServicesContainer = loadable(() => import('./Modules/Services/ServicesContainer'), {
  fallback: <div>Loading...</div>,
});

const TradeInContainer = loadable(() => import('./Modules/Tradein/TradeInContainer'), {
  fallback: <div>Loading...</div>,
});

const ModelDetailContainer = loadable(() => import('./Modules/ModelDetail/ModelDetail'), {
  fallback: <div>Loading...</div>,
});

const InExContainer = loadable(() => import('./Modules/car/InterierExterior/InterierContainer'), {
  fallback: <div>Loading...</div>,
});

const Photo360InteriorContainer = loadable(() => import('./Modules/car/photoInterior/PhotoContainer'), {
  fallback: <div>Loading...</div>,
});
const CarFeatureContainer = loadable(() => import('./Modules/car/feature/FeatureContainer'), {
  fallback: <div>Loading...</div>,
});
const EngineContainer = loadable(() => import('./Modules/car/engine/EngineContainer'), {
  fallback: <div>Loading...</div>,
});
const EngineDescContainer = loadable(() => import('./Modules/car/engindesc/EngineContainer'), {
  fallback: <div>Loading...</div>,
});
const ComfortContainer = loadable(() => import('./Modules/car/comfort/ComfortContainer'), {
  fallback: <div>Loading...</div>,
});
const Photo360Container = loadable(() => import('./Modules/car/photo360/Photo360Container'), {
  fallback: <div>Loading...</div>,
});
const TiresContainer = loadable(() => import('./Modules/car/tires/TiresContainer'), {
  fallback: <div>Loading...</div>,
});
const ComplectContainer = loadable(() => import('./Modules/car/complect/ComplectContainer'), {
  fallback: <div>Loading...</div>,
});
const CompareContainer = loadable(() => import('./Modules/car/compare/CompareContainer'), {
  fallback: <div>Loading...</div>,
});
const CompareTitleContainer = loadable(() => import('./Modules/car/compare/Title/CompareTitleContainer'), {
  fallback: <div>Loading...</div>,
});
const CompareFeatureContainer = loadable(() => import('./Modules/car/compare/feature/CompareFeatureContainer'), {
  fallback: <div>Loading...</div>,
});
const IComplectContainer = loadable(() => import('./Modules/IComplect/IComplectContainer'), {
  fallback: <div>Loading...</div>,
});
const IComplectFeatureContainer = loadable(() => import('./Modules/IComplect/feature/FeatureContainer'), {
  fallback: <div>Loading...</div>,
});
const ColorContainer = loadable(() => import('./Modules/color/ColorContainer'), {
  fallback: <div>Loading...</div>,
});
const ParamContainer = loadable(() => import('./Modules/car/complect/Param/ParamContainer'), {
  fallback: <div>Loading...</div>,
});
const ParamDetailContainer = loadable(() => import('./Modules/car/complect/Param/detail/DetailContainer'), {
  fallback: <div>Loading...</div>,
});
const UsedCardContainer = loadable(() => import('./Modules/car/used/UsedContainer'), {
  fallback: <div>Loading...</div>,
});
const NewsGalleryContainer = loadable(() => import('./Modules/News/gallery/GalleryContainer'), {
  fallback: <div>Loading...</div>,
});
const ContactContainer = loadable(() => import('./Modules/contact/ContactContainer'), {
  fallback: <div>Loading...</div>,
});

function App() {
  return (
    <ScrollToTop>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <Switch> */}
      <Route exact path='/signin' component={LoginContainer} />

      <Route
        render={() => (
          <div className='App'>
            <Layout />

            <Switch>
              <PrivateRoute exact path='/' component={NewModelsSliderContainer} />
              <PrivateRoute exact path='/home/slider' component={MainSliderContainer} />
              <PrivateRoute exact path='/about' component={AboutContainer} />
              <PrivateRoute exact path='/services' component={ServicesContainer} />
              <PrivateRoute exact path='/tradein' component={TradeInContainer} />
              <PrivateRoute exact path='/models' component={ModelsContainer} />
              <PrivateRoute exact path='/news' component={NewsContainer} />
              <PrivateRoute exact path='/news/:newsId/gallery' component={NewsGalleryContainer} />
              <PrivateRoute exact path='/home/NewModelsSlider' component={NewModelsSliderContainer} />
              <PrivateRoute exact path='/home/slider' component={MainSliderContainer} />
              <PrivateRoute exact path='/cars' component={CarsContainer} />
              <PrivateRoute exact path='/cars/in-ex/:carId' component={InExContainer} />
              <PrivateRoute exact path='/cars/photo360int/:carId' component={Photo360InteriorContainer} />
              <PrivateRoute exact path='/cars/feature/:carId' component={CarFeatureContainer} />
              <PrivateRoute exact path='/cars/engine/:carId' component={EngineContainer} />
              <PrivateRoute exact path='/cars/engine-desc/:engineId' component={EngineDescContainer} />
              <PrivateRoute exact path='/cars/comfort/:carId' component={ComfortContainer} />
              <PrivateRoute exact path='/cars/photo360/:carId' component={Photo360Container} />
              <PrivateRoute exact path='/cars/tires/:carId' component={TiresContainer} />
              <PrivateRoute exact path='/cars/complect/:carId' component={ComplectContainer} />
              <PrivateRoute exact path='/cars/complect/:complectId/param' component={ParamContainer} />
              <PrivateRoute exact path='/cars/:carId/complect/compare' component={CompareContainer} />
              <PrivateRoute exact path='/cars/:carId/compare/:compareId/title' component={CompareTitleContainer} />
              <PrivateRoute exact path='/cars/:carId/compare/feature/:compareTitleId' component={CompareFeatureContainer} />
              <PrivateRoute exact path='/cars/param/:complectParamId/detail' component={ParamDetailContainer} />
              <PrivateRoute exact path='/icomplect' component={IComplectContainer} />
              <PrivateRoute exact path='/icomplect/feature/:complectId' component={IComplectFeatureContainer} />
              <PrivateRoute exact path='/colors' component={ColorContainer} />
              <PrivateRoute exact path='/used/car' component={UsedCardContainer} />
              <PrivateRoute exact path='/contact' component={ContactContainer} />
            </Switch>
          </div>
        )}
      />
      {/* </Switch> */}
    </ScrollToTop>
  );
}

export default withTranslation()(App);
