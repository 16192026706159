import fetch from "./fetch";

export const fetchAllNews = (page = 0) =>
  fetch().get(`/admin/news/findall?page=${page}`);

export const fetchNewsById = (id) => fetch().get(`/api/news/get/${id}`);

export const fetchAllHashtags = () => fetch().get(`/api/hashtag/findall`);

export const createNews = ({
  slugUrl,
  carid,
  displayText,
  displayTextGEO,
  prom,
  share,
  sort,
  testDrive,
  text,
  textGEO,
  title,
  titleGEO,
  file,
}) => {
  let payload = new FormData();
  if (carid) payload.append("carid", carid);
  payload.append("slugUrl", slugUrl);
  payload.append("displayText", displayText);
  payload.append("displayTextGEO", displayTextGEO);

  payload.append("prom", prom);
  payload.append("share", share ? 1 : 0);
  payload.append("sort", sort);
  payload.append("testdrive", testDrive ? true : false);
  payload.append("text", text);
  payload.append("textGEO", textGEO);
  payload.append("title", title);

  payload.append("titleGEO", titleGEO);
  payload.append("file", file);
  return fetch().post(`/admin/news/create`, payload);
};

export const updateNews = ({
  id,
  slugUrl,
  carid,
  displayText,
  displayTextGEO,
  prom,
  share,
  testDrive,
  sort,
  text,
  textGEO,
  title,
  titleGEO,
  file,
}) => {
  let payload = new FormData();
  if (carid) payload.append("carid", carid);
  payload.append("id,", id);
  payload.append("slugUrl", slugUrl);
  payload.append("displayText", displayText);
  payload.append("displayTextGEO", displayTextGEO);

  payload.append("prom", prom);
  payload.append("share", share ? 1 : 0);
  payload.append("testdrive", testDrive ? true : false);
  payload.append("sort", sort);
  payload.append("text", text);
  payload.append("textGEO", textGEO);
  payload.append("title", title);

  payload.append("titleGEO", titleGEO);
  if (file) payload.append("file", file);
  return fetch().post(`/admin/news/update/${id}`, payload);
};

export const fetchDeleteNews = (id) => fetch().post(`/admin/news/remove/${id}`);

/**
 *
 * @param {*} carId
 * News Gallery
 *
 */
export const fetchNewsGallery = (newsId) =>
  fetch().get(`/admin/news/gallery/findall/${newsId}`);

export const fetchAdminCreateNewsGallery = ({ newsId, images }) => {
  let payload = new FormData();
  if (images) {
    images.forEach((element, index) => {
      payload.append(`images`, element);
    });
  }

  return fetch().post(`/admin/news/gallery/create/${newsId}`, payload);
};

export const fetchAdminUpdateNewsGallery = ({ modelid, model }) => {
  let payload = new FormData();
  payload.append(`model`, model);

  return fetch().post(`/admin/usedcars/model/update/${modelid}`, payload);
};

export const fetchDeleteNewsGallery = (id) =>
  fetch().post(`/admin/news/gallery/delete/${id}`);
