import { takeEvery, call, all, put } from 'redux-saga/effects';
import { fetchAllHashtags } from '../../../api/news.api';
import { setCategories, setCategoriesLoading, setNewsLoading } from '../action/news.action';
import { START_FETCH_CATEGORIES, START_FETCH_NEWS } from '../const/news.const';

// actions

//api

function* startFetchNewsAsync(actions) {
  let { payload } = actions;
  yield put(setNewsLoading(true));
  try {
  } catch (error) {
    yield put(setNewsLoading(false));
    // yield put(setCityLoader(false))
  }
}

function* onStartFetchNews() {
  yield takeEvery(START_FETCH_NEWS, startFetchNewsAsync);
}
function* onStartFetchCategoriesAsync(actions) {
  let { payload } = actions;
  yield put(setCategoriesLoading(true));
  let { data } = yield fetchAllHashtags();
  yield put(setCategories(data));
  try {
  } catch (error) {
    yield put(setCategoriesLoading(false));
    // yield put(setCityLoader(false))
  }
}

function* onStartFetchCategories() {
  yield takeEvery(START_FETCH_CATEGORIES, onStartFetchCategoriesAsync);
}

export default function* newsSaga() {
  yield all([call(onStartFetchNews), call(onStartFetchCategories)]);
}
