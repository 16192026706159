import axios from 'axios';
import { baseUrl } from '../helper/baseUrl';
import { toast } from 'react-toastify';
import i18n from '../i18n';
import { clearUserToken } from '../package/store/action/user.actions';
import { store } from '../package/store/index';

export const sleep = (ms) => (response) => new Promise((resolve) => setTimeout(() => resolve(response), ms));

const fetch = (isPrivate = true, timeout = 10000) => {
  let config = {
    baseURL: baseUrl,
    timeout,
  };

  // if(Object.keys(body).length){
  //     config.data = body
  // }

  let fetch = axios.create(config);

  fetch.interceptors.request.use(function (config) {
    config.headers['accept-language'] = i18n.language;
    if (isPrivate && localStorage.getItem('Hyundai_Token')) {
      let token = localStorage.getItem('Hyundai_Token');
      config.headers['Authorization'] = `Hyundai ${token}`;
    }

    return config;
  });

  fetch.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem('Hyundai_Token');
        //place your reentry code
        store.dispatch(clearUserToken());
      } else if (error.response.status === 500) {
        toast.error('დაფიქსირდა შეცდომა, გთხოვ სცადოთ მოგვიანებით');
      } else if (error.response.status === 400) {
        toast.error('დაფიქსირდა შეცდომა');
      }

      return Promise.reject(error);
    }
  );

  // return fetch
  return fetch;
};

export default fetch;
