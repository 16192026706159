export let baseUrl
export let staticBaseUrl
export let authorizationUrl
export let grant_type
export let client_id
export let client_secret

export let setBaseUrls = ({
    baseUrl: _baseUrl,
    staticBaseUrl: _staticContentBaseUrl,
    authorizationUrl: _authorizationUrl,
    grant_type:_grant_type,
    client_secret:_client_secret,
    client_id:_client_id
}) => {
    baseUrl = _baseUrl
    staticBaseUrl = _staticContentBaseUrl
    authorizationUrl = _authorizationUrl
    grant_type = _grant_type
    client_secret = _client_secret
    client_id=_client_id
}
