import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { history } from './helper/history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ConfigurStore from './package/store';
import i18n from './i18n';
import App from './App';

import { setBaseUrls } from './helper/baseUrl';
import { I18nextProvider } from 'react-i18next';

let xhrConfig;

const fetchConfig = () =>
  new Promise((resolve, reject) => {
    xhrConfig = new XMLHttpRequest();
    xhrConfig.open('GET', '/config.json', true);
    xhrConfig.setRequestHeader('Cache-Control', 'no-cache');
    xhrConfig.onload = resolve;
    xhrConfig.onerror = reject; // () => reject(xhrConfig.statusText); //  console.error(xhrConfig.statusText);
    xhrConfig.send(null);
  });

function onConfigResult(config) {
  // set base porperties
  setBaseUrls({
    baseUrl: config.baseUrlForApi,
    staticBaseUrl: config.staticBaseUrl,
    authorizationUrl: config.authorizationUrl,
    client_id: config.client_id,
    client_secret: config.client_secret,
    grant_type: config.grant_type,
  });

  if (process.env.NODE_ENV !== 'development') console.log = () => {};
}

function requestOnLoad() {
  if (xhrConfig.readyState === 4 && xhrConfig.status === 200) {
    let serverConfig = JSON.parse(xhrConfig.responseText);
    onConfigResult(serverConfig);
    const { store, persistor } = ConfigurStore();
    ReactDOM.render(
      // <React.StrictMode>
      <React.Suspense fallback='loading...'>
        <I18nextProvider i18n={i18n}>
          <Router history={history}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </Provider>
          </Router>
        </I18nextProvider>
      </React.Suspense>,
      // </React.StrictMode>,
      document.getElementById('root')
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // serviceWorker.register();
  }
}

fetchConfig().then(requestOnLoad).catch();
