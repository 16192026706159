import { SET_CATEGORIES, SET_CATEGORIES_LOADING, SET_NEWS, SET_NEWS_LOADING } from '../const/news.const';

const initialState = {
  isLoading: true,
  items: [],
  isLoadngHashtag: true,
  hashTags: [],
};

export default function newsReducr(state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORIES_LOADING: {
      state = {
        ...state,
        isLoadngHashtag: action.payload,
      };
      break;
    }
    case SET_NEWS_LOADING: {
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;
    }
    case SET_NEWS: {
      state = {
        ...state,
        items: action.payload,
        isLoading: false,
      };
      break;
    }
    case SET_CATEGORIES: {
      state = {
        ...state,
        hashTags: action.payload,
        isLoadngHashtag: false,
      };
      break;
    }
    default:
      return state;
  }
  return state;
}
