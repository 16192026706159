import { CLEAR_USER, SET_USER, SET_USER_LOADER } from '../const/user.const';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: null,
};

export default function newsReducr(state = initialState, action) {
  switch (action.type) {
    case SET_USER_LOADER: {
      state = {
        ...state,
        isLoading: action.payload,
      };
      break;
    }
    case SET_USER: {
      state = {
        ...state,
        user: action.payload,
        isLoading: false,
        isAuthenticated: !!action.payload,
      };
      break;
    }
    case CLEAR_USER: {
      state = {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: null,
      };
      break;
    }
    default:
      return state;
  }
  return state;
}
